@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,600;1,400&display=swap');

:root{
  --montserrat: "Montserrat", "sans-serif";
  --color-grey: #E5E5E5;
  --color-white: #FFFFFF;
  --color-silver: #d9d9d9;
  --color-orange: #FCA311;
  --color-dark: #232323;
  --color-dark-secondary: #333333;

  --h1-size: 1.4rem;
  --h2-size: 1.3rem;
  --text-size: 0.9rem;
  --button-size: 1rem;
}

html{
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background-color: var(--color-dark);
}
